import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby';

const IndexPage = () => (
    <Layout>
        <div className="p404">
            <h1>Oops!</h1>
            <h2>404 - Diese Seite konnte nicht gefunden werden</h2>
            <Link to="/">Zurück zur Startseite</Link>
        </div>
    </Layout>
)

export default IndexPage
